import React, { useEffect, useState } from "react";
import { Heading } from "../../../../subComponents/Heading";
import { Img } from "../../../../subComponents/img";
import { NavLink, useParams } from "react-router-dom";
import { baseurl } from "../../../../CommonComponents/baseurl";

import {
    Grid,
    Skeleton,
  } from "@mui/material";

const UpvcVariantSubComp = () => {
  const [product, setProduct] = useState([]);
  const { slug } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMorePages, setHasMorePages] = useState(true);
  

  const fetchProduct = async (page) => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("slug", slug);
      formData.append("product_page", page);

      const response = await fetch(`${baseurl}/api/product`, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();
      if (data.success === "1") {
        setProduct((prevProducts) => [...prevProducts, ...data.data]);

        if (page >= data.pagination_data.total_pages) {
          setHasMorePages(false);
        }
      } else {
        console.error("Failed to fetch Product data:", data.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProduct(currentPage);
  }, [currentPage]);

  const loadMoreProducts = () => {
    if (hasMorePages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const showLessProducts = () => {
    setProduct([]);
    setCurrentPage(1);
    setHasMorePages(true);
  };

  return (
    <>
      <div className="desktop_header_spacer" style={{maxHeight:"70px"}} ></div>
      <br />
      <section className="product_page_section">
        <div style={{ width: "max-content", margin: "auto", padding: "0 0" }}>
          {slug === null?
          <Skeleton variant="text" height={'40px'} width={'200px'} ></Skeleton> :

            <Heading title={slug} />}
        </div>

        <div className="window_option_container">
          {
            product.length === 0?
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {Array.from(new Array(8)).map((_, index) => (
          <Grid item xs={12} sm={6} md={3} lg={3} key={index}>
            <div style={{maxWidth:'300px', overflow:'hidden'}}>
            <Skeleton variant="rectangular" sx={{ maxWidth: 300, maxHeight: "300px", minHeight: "230px", mx: "auto" }} />
            <br />
            <Skeleton variant="rectangular" width="60%" />
            </div>
          </Grid>
        ))}
      </Grid>
          :
          product.map((item) => (
            <NavLink to={`/products/${item.slug}`} key={item.slug}>
              <div>
                <div className="window_option_image shadow">
                  <Img src={item.image} alt={item.title} />
                </div>
                <button className="window_option_button shadow"  dangerouslySetInnerHTML={{__html: item.title}}>
                  
                </button>
              </div>
            </NavLink>
          ))}
        </div>

        <div className="load-more-container">
          {hasMorePages && (
            <button onClick={loadMoreProducts} disabled={isLoading} className="loadmorebtn">
              {isLoading ? "Loading..." : "Show More"}
            </button>
          )}
          {!hasMorePages && currentPage > 1 && (
            <button onClick={showLessProducts} className="loadmorebtn">
              Show Less
            </button>
          )}
        </div>
      </section>
    </>
  );
};

export default UpvcVariantSubComp;




