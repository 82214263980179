import React, { useEffect } from "react";
import { Img } from "../../../../subComponents/img";
import { ReadMore } from "../../../../CommonComponents/readMore";

import AOS from "aos";
import "aos/dist/aos.css";

const ServiceCard=({iconUrl,title,description,...resprops})=>{

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  
    return(
        <>
          <div className="card shadow costom_card" style={{width:"15rem"}} {...resprops}  data-aos="flip-right" data-aos-duration="2000" >
            <div className="service_icons"><Img src={iconUrl} alt={"service icon"} /></div>
                <div className="card-body">
                 <h6 className="card-title">{title}</h6>
                  <p className="card-text">{description}</p>
                  {/* <ReadMore className={"read_hover_btn"} /> */}
                  <br />
                </div>
           </div>
        </>
    );
}
export {ServiceCard}