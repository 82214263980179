// import React, { useState } from "react";
// import { Subheading } from "../../../../subComponents/subHeading";
// import { Img } from "../../../../subComponents/img";
// import { Heading } from "../../../../subComponents/Heading";
// import { NavLink } from "react-router-dom";
// import { Text } from "../../../../subComponents/text";
// import Skeleton from '@mui/material/Skeleton';


// const SingleProduct = ({
//   imageUrl,
//   image2,
//   image3,
//   image4,
//   pagetitle1,
//   pagetitle2,
//   productAccessories,
//   description,
//   descriptionData,
// }) => {
//   const [showFullDescription, setShowFullDescription] = useState(false);
//   const [currentImage, setCurrentImage] = useState(null);
//   const [expandedItem, setExpandedItem] = useState("data1");

//   const toggleAccordion = (id) => {
//     if (expandedItem === id) {
//       setExpandedItem(null);
//     } else {
//       setExpandedItem(id);
//     }
//   };

//   const toggleDescription = () => {
//     setShowFullDescription(!showFullDescription);
//   };

//   const handleImageClick = (image) => {
//     setCurrentImage(image);
//   };

//   return (
//     <section className="single_page_main_section">
//       {/* Single page left section start from here */}
//       <div className="single_left_section">
//         <div className="big_image">
//           {
//             imageUrl == null ? 
//             <Skeleton variant="rectangular" width="100%" height={"100%"} style={{minHeight:'300px', minWidth:'300px'}} >
//             <div style={{ paddingTop: '57%' }} />
//           </Skeleton>
//           :
//           <Img
//             src={currentImage === null ? imageUrl : currentImage}
//             style={{ maxHeight: "350px" }}
//             alt={'product image'}
//           />
//           }

//         </div>
//       </div>
//       {/* Single page left section close from here */}

//       {/* Single page right section start from here */}
//       <div className="single_right_section">
//         <div className="single_product_heading">
//           <Heading
//             style={{ marginBottom: "0.7rem" }}
//             orangeheading={pagetitle1}
//             content={pagetitle2}
//           />
//         </div>
//         <div className="innersingle_right_section">
//           {descriptionData === undefined
//             ? 
//             <>
//           <Skeleton variant="rectangular" width="100%" height={"100%"} style={{minHeight:'30px', minWidth:'300px'}} ></Skeleton><br />
//           <Skeleton variant="rectangular" width="100%" height={"100%"} style={{minHeight:'30px', minWidth:'300px', marginTop:'1rem'}} ></Skeleton>
//           <br />{
//             Array.from(new Array(3)).map((_,index)=>(
//               <>
//                 <Skeleton variant="text" width="100p%" />
//              </>
//             ))
//           }
//           <br />
//           <Skeleton variant="rectangular" width="100%" height={"100%"} style={{minHeight:'30px', minWidth:'300px'}} ></Skeleton>
//           <Skeleton variant="rectangular" width="100%" height={"100%"} style={{minHeight:'30px', minWidth:'300px'}} ></Skeleton>
//             </>

//             : descriptionData.map((item) => (
//                 <div className="perticuler_accordion" key={item.id}>
//                   <div className="accordion_question">
//                     <h6 dangerouslySetInnerHTML={{ __html: item.title }}></h6>
//                     <p
//                       style={{
//                         fontWeight: "600",
//                         fontSize: "1.2rem",
//                         cursor: "pointer",
//                       }}
//                       onClick={() => toggleAccordion(item.id)}
//                     >
//                       {expandedItem === item.id ? "-" : "+"}
//                     </p>
//                   </div>
//                   {expandedItem === item.id && (
//                     <div style={{ marginTop: "0.7rem" }}>
//                       {Array.isArray(item.description) ? (
//                         <ul>
//                           {item.description.map((ans, index) => (
//                             <li key={index}>
//                               <p dangerouslySetInnerHTML={{ __html: ans}}></p>
//                             </li>
//                           ))}
//                         </ul>
//                       ) : (
//                         <>
//                         <p dangerouslySetInnerHTML={{ __html: item.description}}></p>

//                         <p dangerouslySetInnerHTML={{ __html: item.specification}}></p>
//                         </>
//                       )}
//                     </div>
//                   )}
//                 </div>
//               ))}

//           <div className="single_product_accessories_container">
//             {productAccessories === null
//               ? ""
//               : productAccessories.map((items) => {
//                   return (
//                     <NavLink
//                       key={items.slug}
//                       to={`/accessories-shop/${items.slug}`}
//                     >
//                       <div className="single_accessories">
//                         <div className="single_accessories_image">
//                           <Img src={items.image} alt={"image png 4.png"} />
//                         </div>
//                         <Subheading
//                           // orangetext={items.title}
//                           content={items.title}
//                           style={{ fontSize: "0.875rem" }}
//                         />
//                       </div>
//                     </NavLink>
//                   );
//                 })}
//           </div>
//         </div>
//       </div>
//       {/* Single page right section close from here */}
//     </section>
//   );
// };

// export { SingleProduct };




import React, { useState } from "react";
import { Subheading } from "../../../../subComponents/subHeading";
import { Img } from "../../../../subComponents/img";
import { Heading } from "../../../../subComponents/Heading";
import { NavLink } from "react-router-dom";
import { Text } from "../../../../subComponents/text";
import Skeleton from '@mui/material/Skeleton';


const SingleProduct = ({
  imageUrl,
  image2,
  image3,
  image4,
  pagetitle1,
  pagetitle2,
  productAccessories,
  description,
  descriptionData,
}) => {
  const [currentImage, setCurrentImage] = useState(null);



  return (
    <section className="single_page_main_section">
      {/* Single page left section start from here */}
      <div className="single_left_section">
        <div className="big_image">
          {
            imageUrl == null ? 
            <Skeleton variant="rectangular" width="100%" height={"100%"} style={{minHeight:'300px', minWidth:'300px'}} >
            <div style={{ paddingTop: '57%' }} />
          </Skeleton>
          :
          <Img
            src={currentImage === null ? imageUrl : currentImage}
            style={{ maxHeight: "350px" }}
            alt={'product image'}
          />
          }

        </div>
      </div>
      {/* Single page left section close from here */}

      {/* Single page right section start from here */}
      <div className="single_right_section">
        <div className="single_product_heading">
          <Heading
            style={{ marginBottom: "0.7rem" }}
            orangeheading={pagetitle1}
            content={pagetitle2}
          />
        </div>
        <div className="innersingle_right_section">
          {descriptionData === undefined
            ? 
            <>
          <Skeleton variant="rectangular" width="100%" height={"100%"} style={{minHeight:'30px', minWidth:'300px'}} ></Skeleton><br />
          <Skeleton variant="rectangular" width="100%" height={"100%"} style={{minHeight:'30px', minWidth:'300px', marginTop:'1rem'}} ></Skeleton>
          <br />{
            Array.from(new Array(3)).map((_,index)=>(
              <>
                <Skeleton variant="text" width="100p%" />
             </>
            ))
          }
          <br />
          <Skeleton variant="rectangular" width="100%" height={"100%"} style={{minHeight:'30px', minWidth:'300px'}} ></Skeleton>
          <Skeleton variant="rectangular" width="100%" height={"100%"} style={{minHeight:'30px', minWidth:'300px'}} ></Skeleton>
            </>

            : descriptionData.map((item) => (
                <div className="perticuler_accordion" key={item.id}>
                  <div className="accordion_question">
                    <h6 dangerouslySetInnerHTML={{ __html: item.title }}></h6>
                    <p
                      style={{
                        fontWeight: "600",
                        fontSize: "1.2rem",
                        cursor: "pointer",
                      }}
                    >
                      { "-"}
                    </p>
                  </div>
                  { (
                    <div style={{ marginTop: "0.7rem" }}>
                      {Array.isArray(item.description) ? (
                        <ul>
                          {item.description.map((ans, index) => (
                            <li key={index}>
                              <p dangerouslySetInnerHTML={{ __html: ans}}></p>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <>
                        <p dangerouslySetInnerHTML={{ __html: item.description}}></p>

                        <p dangerouslySetInnerHTML={{ __html: item.specification}}></p>
                        </>
                      )}
                    </div>
                  )}
                </div>
              ))}

          <div className="single_product_accessories_container">
            {productAccessories === null
              ? ""
              : productAccessories.map((items) => {
                  return (
                    <NavLink
                      key={items.slug}
                      to={`/accessories-shop/${items.slug}`}
                    >
                      <div className="single_accessories">
                        <div className="single_accessories_image">
                          <Img src={items.image} alt={"image png 4.png"} />
                        </div>
                        <Subheading
                          // orangetext={items.title}
                          content={items.title}
                          style={{ fontSize: "0.875rem" }}
                        />
                      </div>
                    </NavLink>
                  );
                })}
          </div>
        </div>
      </div>
      {/* Single page right section close from here */}
    </section>
  );
};

export { SingleProduct };
