import React, { useState } from 'react';
import { SingleProduct } from "../SingleProducts/singleProducts";
import Header from "../../../../CommonComponents/header";
import { MobileHeader } from "../../../../CommonComponents/mobileHeader";
import { Footer } from "../../../../CommonComponents/footer";
import { Heading } from "../../../../subComponents/Heading";
import ProductOptionSection from "../../Components/productOptionSection";
import UpvcVariantBannerSlider from "../../Components/upvcvariantbannerSlider";
import { Img } from "../../../../subComponents/img";
import { Modal, Box } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: "450px",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const UpvcDoorPage = () => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const handleOpen = (imageSrc) => {
    setSelectedImage(imageSrc);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  return (
    <>
      <Header />
      <MobileHeader />
      <br />
      <SingleProduct
        imageUrl="assets/images/product/windon_banner.png"
        pagetitle1="uPVC"
        pagetitle2={"-Door "}
      />
      <ProductOptionSection sectionTitle="Door Glass Option" buttontitle1="Clear glass" buttontitle2="Tinted glass" buttontitle3="Frosted glass" buttontitle4="Reflected glass" />
      <br />
      <UpvcVariantBannerSlider />
      <br />
      <section>
        <div style={{ width: "max-content", margin: "auto", padding: "0 0" }}>
          <Heading title={"Door Option"} />
        </div>

        <div className="window_option_container">
          <div>
            <div className="window_option_image">
              <Img src={"assets/images/product/Sliding Door.png"} alt={'Sliding Door.png'} />
            </div>
            <button className="window_option_button shadow" onClick={() => handleOpen("assets/images/product/Sliding Door.png")}>Sliding Door</button>
          </div>

          <div>
            <div className="window_option_image">
              <Img src={"assets/images/product/Casement Door.png"} alt={'Casement Door.png'} />
            </div>
            <button className="window_option_button shadow" onClick={() => handleOpen("assets/images/product/Casement Door.png")}>Casement Door</button>
          </div>

          <div>
            <div className="window_option_image">
              <Img src={"assets/images/product/Lift and slide door.png"} alt={'Lift and slide door.png'} />
            </div>
            <button className="window_option_button shadow" onClick={() => handleOpen("assets/images/product/Lift and slide door.png")}>Lift & Slide Door</button>
          </div>

          <div>
            <div className="window_option_image">
              <Img src={"assets/images/product/sliding window.jpg.png"} alt={'sliding window.jpg.png'} />
            </div>
            <button className="window_option_button shadow" onClick={() => handleOpen("assets/images/product/sliding window.jpg.png")}>Sliding</button>
          </div>
        </div>
      </section>

      <Footer />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <img src={selectedImage} alt="Door Option" style={{ width: '100%', maxWidth: "400px" }} />
        </Box>
      </Modal>
    </>
  );
};

export default UpvcDoorPage;

