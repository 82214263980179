import React, { useEffect, useState } from "react";
import Header from "../../CommonComponents/header";
import { Footer } from "../../CommonComponents/footer";
import { BlogHeroSection } from "./Components/blogHeroSection";
import { Heading } from "../../subComponents/Heading";
import { BlogCard } from "./Components/blogCard";
import { MobileHeader } from "../../CommonComponents/mobileHeader";
import { NavLink } from "react-router-dom";
import { baseurl } from "../../CommonComponents/baseurl";
import {
  Grid,
  Skeleton,
} from "@mui/material";
import { Helmet } from "react-helmet";
import { MetaTags } from "../../Seo/HelmetComponent";

function BlogsPage({ title, description, keyword }) {
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [showMore, setShowMore] = useState(true);

  useEffect(() => {
    fetchBlogs(currentPage);
  }, [currentPage]);

  const fetchBlogs = async (page) => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append('blog_page', page);

      const response = await fetch(`${baseurl}/api/blog-list`, {
        method: 'POST',
        body: formData
      });

      const data = await response.json();
      if (data.success === "1") {
        setBlogs((prevBlogs) => [...prevBlogs, ...data.data]);
        setTotalPages(data.pagination_data.total_pages);
        if (page >= data.pagination_data.total_pages) {
          setShowMore(false);
        }
      } else {
        console.error('Failed to fetch blog data:', data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const loadMoreBlogs = () => {
    if (showMore) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <>
      <Helmet>
        <MetaTags title={title} description={description} keyword={keyword} />
        {/* OG Tags:-  */}
        <meta property="og:title" content="Blogs | Alu Empire" />
        <meta property="og:site_name" content="Blogs | Alu Empire" />
        <meta property="og:url" content="https://www.aluempire.com/blogs" />
        <meta property="og:description" content="Alu Empire provides you the best aluminium skirting and led skirting freedom to create custom doors that perfectly complement the aesthetic and style of your spaces" />
        <meta property="og:type" content="" />
        <meta property="og:image" content="https://www.aluempire.com/assets/images/Alu%20Empire%20Logo.png" />


        {/* // Twitter Card:- */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@Blog | Alu Empire" />
        <meta name="twitter:title" content="@Blog | Alu Empire" />
        <meta name="twitter:description" content="Alu Empire provides you the best aluminium skirting and led skirting freedom to create custom doors that perfectly complement the aesthetic and style of your spaces" />
        <meta name="twitter:image" content="https://www.aluempire.com/assets/images/Alu%20Empire%20Logo.png" />
      </Helmet>
      <Header />
      <MobileHeader />
      <div className="desktop_header_spacer"></div>
      <BlogHeroSection
        pagetitle={"BLOGS"}
        pagedescription={
          "Explore our world of innovation, creativity, adventure, and endless possibilities"
        }
      />
      <section className="blogs_section">
        <Heading style={{ paddingBottom: "2rem", textalign: "center" }} title={"Recent Blogs"} />
        <div className="blogs_container">
          {blogs.length === 0 ?
            <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              {Array.from(new Array(8)).map((_, index) => (
                <Grid item xs={6} sm={4} md={4} lg={4} key={index}>
                  <Skeleton variant="rectangular" sx={{ maxWidth: 350, maxHeight: "300px", minHeight: "300px", mx: "auto" }} />
                  <Skeleton variant="text" style={{ height: "40px", width: "350px", margin: "auto" }}  ></Skeleton>
                  <Skeleton variant="text" style={{ height: "40px", width: "300px", margin: "auto" }}  ></Skeleton>
                </Grid>
              ))}
            </Grid>
            :
            blogs.map((items, index) => (
              <NavLink key={index} to={`/blog-details-page/${items.slug}`}>
                <BlogCard
                  title={items.title}
                  discription={items.description}
                  blogtime={formatDate(items.datetime)}
                  imageurl={items.image}
                />
              </NavLink>
            ))
          }
        </div>
        <div className="load-more-container">
          {showMore && (
            <button onClick={loadMoreBlogs} disabled={isLoading} className="loadmorebtn" >
              {isLoading ? 'Loading...' : 'Show More'}
            </button>
          )}
          {!showMore && (
            <button onClick={() => setBlogs([]) & setCurrentPage(1) & setShowMore(true)} className="loadmorebtn" >
              Show Less
            </button>
          )}
        </div>
      </section>
      <Footer />
    </>
  );
}

export default BlogsPage;
