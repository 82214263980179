// import React, { useEffect } from "react";

// const Countersection = () => {
//   return (
//     <>
//       <section className="outer_counter_section">
//         <div className="counter_section">
//           <video
//             width="100%"
//             height="100%"
//             autoPlay
//             muted
//             loop
//             style={{ objectFit: "cover" }}
//           >
//             <source src="assets/images/banner.webm" type="video/mp4" />
//           </video>
//           <div className="counter_section_images_overlay">
//             <div className="counter_section_images_inneroverlay"></div>
//           </div>
//         </div>
//         <div className="counter_container shadow">
//           <div className="counter_innercontainer">
//             <div className="counters">
//               <h3>1.5K</h3>
//               <h6 style={{ color: "#FA853D" }}>Happy Clients</h6>
//             </div>
//             <div className="counters">
//               <h3>10+</h3>
//               <h6 style={{ color: "#FA853D" }}>Years</h6>
//             </div>
//             <div className="counters">
//               <h3>386+</h3>
//               <h6 style={{ color: "#FA853D" }}>Projects</h6>
//             </div>
//             <div className="counters">
//               <h3>99%</h3>
//               <h6 style={{ color: "#FA853D" }}>Quality</h6>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };
// export { Countersection };



import React, { useEffect } from "react";

const Countersection = () => {
  useEffect(() => {
    const counters = document.querySelectorAll(".counter");
    const speed = 500; 

    const resetCounters = () => {
      counters.forEach((counter) => (counter.innerText = "0"));
    };

    const animateCounters = () => {
      counters.forEach((counter) => {
        const updateCount = () => {
          const target = +counter.getAttribute("data-target");
          const count = +counter.innerText;

          
          const increment = target / speed;

          if (count < target) {
            counter.innerText = Math.ceil(count + increment);
            setTimeout(updateCount, 1);
          } else {
            counter.innerText = target;
          }
        };

        updateCount();
      });
    };

    const section = document.querySelector(".outer_counter_section");
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          resetCounters();
          animateCounters();
        }
      },
      { threshold: 0.5 }
    );

    if (section) {
      observer.observe(section);
    }

    return () => {
      if (section) {
        observer.unobserve(section);
      }
    };
  }, []);

  return (
    <section className="outer_counter_section">
      <div className="counter_section">
        {/* <video
          width="100%"
          height="100%"
          autoPlay
          muted
          loop
          style={{ objectFit: "cover" }}
        >
          <source src="assets/images/banner.webm" type="video/mp4" />
        </video> */}

<div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active" style={{overflow:"hidden", maxHeight:'500px'}} >
      <img src="/assets/images/banner1.jpg" class="d-block w-100" alt="home banner1" style={{objectFit:"cover"}} />
    </div>
    <div class="carousel-item">
      <img src="/assets/images/banner2.jpg" class="d-block w-100" alt="home banner2"/>
    </div>
    <div class="carousel-item">
      <img src="/assets/images/banner3.jpg" class="d-block w-100" alt="home banner3"/>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>

        <div className="counter_section_images_overlay">
          <div className="counter_section_images_inneroverlay"></div>
        </div>
      </div>
      <div className="counter_container shadow">
        <div className="counter_innercontainer">
          <div className="counters">
          <div style={{display:"flex", alignItems:"center"}} >
            <h3 className="counter" data-target="1500">0</h3>
            <h3>+</h3>
            </div>
            <h6 style={{ color: "#FA853D" }}>Happy Clients</h6>
          </div>
          <div className="counters">
           <div style={{display:"flex", alignItems:"center"}} >
             <h3 className="counter" data-target="25">0</h3>
             <h3>+</h3>
           </div>
            <h6 style={{ color: "#FA853D" }}>Years</h6>
          </div>
          <div className="counters">
          <div style={{display:"flex", alignItems:"center"}} >
            <h3 className="counter" data-target="386">0</h3>
            <h3>+</h3>
            </div>
            <h6 style={{ color: "#FA853D" }}>Projects</h6>
          </div>
          <div className="counters">
          <div style={{display:"flex", alignItems:"center"}} >
            <h3 className="counter" data-target="99">0</h3>
            <h3>%</h3>
          </div>  
            <h6 style={{ color: "#FA853D" }}>Quality</h6>
          </div>
        </div>
      </div>
    </section>
  );
};

export { Countersection };

