// import React, { useEffect, useState } from "react";
// import { Img } from "../subComponents/img";
// import { NavLink } from "react-router-dom";
// import { Text } from "../subComponents/text";
// import { baseurl } from "./baseurl";
// import { HashLink } from "react-router-hash-link";
// import { Link } from "react-router-dom";
// import SearchBar from "./searchingBar";


// import Modal from "@mui/material/Modal";
// import Snackbar from "@mui/material/Snackbar";
// import Box from "@mui/material/Box";


// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "max-content",
//   bgcolor: "background.paper",
//   // border: '2px solid #000',
//   borderRadius: "2rem",
//   boxShadow: 24,
//   // p: 4,
// };


// const MobileHeader = () => {
//   const [showsidebar, setShowsidebar] = useState(false);
//   const [showproductMenu, setShowproductMenu] = useState(false);
//   const [showproductMenuabout, setShowproductMenuabout] = useState(false);
//   const [showproductMenushowcase, setShowproductMenushowcase] = useState(false);
//   const [showproductMenucontactus, setShowproductMenucontactus] = useState(false);

//   const [category, setCategorys] = useState([]);
//   const [issreaching, setIssearching] = useState(false);



  
//   const [openmodal, setOpenmodal] = React.useState(false);
//   const handleClosemodal = () => setOpenmodal(false);

//   const handleOpen = () => setOpenmodal(true);

//   const [openmodal2, setOpenmodal2] = React.useState(false);
//   const handleClosemodal2 = () => setOpenmodal2(false);

//   const handleOpen2 = () => setOpenmodal2(true);

//   const [customer_name, setCustomerName] = useState("");
//   const [phone_number, setPhoneNumber] = useState("");
//   const [email, setEmail] = useState("");
//   const [required_service, setRequiredService] = useState("uPVC");
//   const [otherdescription, setotherDescription] = useState("");

//   const [successmessege, setSuccessmessege] = useState("");
//   const [error, setError] = useState("");
//   const [open, setOpen] = React.useState(false);



//   // api fetching start from here

//   useEffect(() => {
//     const fetchCategory = async () => {
//       try {
//         const response = await fetch(`${baseurl}/api/category`, {
//           method: "GET",
//         });

//         const data = await response.json();
//         // console.log("Fetched data:", data);
//         if (data.success === "1") {
//           setCategorys(data.data);
//           // console.log("Set category data:", data.data);
//         } else {
//           console.error("Failed to fetch blog data:", data.message);
//         }
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };

//     fetchCategory();
//   }, []);

//   useEffect(() => {
//     // console.log("Category state updated:", category);
//   }, [category]);

//   // api fetching close from here



//   const handleClick = () => {
//     setOpen(true);
//   };

//   const handleClose = (event, reason) => {
//     if (reason === "clickaway") {
//       return;
//     }
//     setOpen(false);
//   };

//   const validateForm = () => {
//     if (!customer_name) {
//       setError("Name is required");
//       return false;
//     }
//     if (!phone_number) {
//       setError("Phone number is required");
//       return false;
//     }
//     if (!email) {
//       setError("Email is required");
//       return false;
//     }
//     if (required_service === "other" && !otherdescription) {
//       setError("Please specify the service");
//       return false;
//     }
//     setError(""); // Clear any previous error
//     return true;
//   };

//   const formSubmit = async () => {
//     if (!validateForm()) {
//       handleClick();
//       return;
//     }

//     try {
//       const response = await fetch(`${baseurl}/api/contact-us`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           customer_name,
//           phone_number,
//           email,
//           required_service,
//           otherdescription,
//         }),
//       });

//       const data = await response.json();
//       if (data.success === "1") {
//         console.log(data.message);
//         setSuccessmessege(data.message);
//         handleClick();
//       } else {
//         console.error("Failed to submit data:", data.message);
//         setError(data.message);
//         handleClick();
//         handleClosemodal();
//       }
//     } catch (error) {
//       console.error("Error submitting data:", error);
//       setError("An error occurred. Please try again.");
//       handleClick();
//     }
//   };

//   useEffect(() => {
//     console.log("required_service:", required_service);
//   }, [required_service]);

//   // api fetching start from here

//   useEffect(() => {
//     const fetchCategory = async () => {
//       try {
//         const response = await fetch(`${baseurl}/api/category`, {
//           method: "GET",
//         });

//         const data = await response.json();
//         // console.log("Fetched data:", data);
//         if (data.success === "1") {
//           setCategorys(data.data);
//           // console.log("Set category data:", data.data);
//         } else {
//           console.error("Failed to fetch blog data:", data.message);
//         }
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };

//     fetchCategory();
//   }, []);

//   useEffect(() => {
//     // console.log("Category state updated:", category);
//   }, [category]);

//   // api fetching close from here


//   return (
//     <>
//       <div className="outer_mobile_header">
//         <header className="mobile_header shadow-sm">
//           <div className="mobile_header_logo_container">
//             <Link to={"/"}>
//               <Img src={"/assets/images/logo.png"} />
//             </Link>
//           </div>

//           <div className="mobile_menu_container">
//             <div> 
//             <div className="">
//                     <div className="search_container" onClick={()=>setIssearching(!issreaching)} >
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         style={{ color: "#fff" }}
//                         width="1.2rem"
//                         height="1.2rem"
//                         viewBox="0 0 15 15"
//                       >
//                         <path
//                           fill="none"
//                           stroke="currentColor"
//                           d="m14.5 14.5l-4-4m-4 2a6 6 0 1 1 0-12a6 6 0 0 1 0 12Z"
//                         />
//                       </svg>
//                     </div>
//                   </div>
//                  {
//                   issreaching &&  <SearchBar/>
//                  }
//             </div>
//             <div
//               className="menu_bnt"
//               onClick={() => {
//                 setShowsidebar(!showsidebar);
//               }}
//             >
//               {showsidebar === true ? (
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   width="1.8em"
//                   height="1.8em"
//                   viewBox="0 0 24 24"
//                 >
//                   <path
//                     fill="currentColor"
//                     d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z"
//                   />
//                 </svg>
//               ) : (
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   width="1.8em"
//                   height="1.8em"
//                   viewBox="0 0 24 24"
//                 >
//                   <path
//                     fill="currentColor"
//                     d="M3 18h18v-2H3zm0-5h18v-2H3zm0-7v2h18V6z"
//                   />
//                 </svg>
//               )}
//             </div>
//           </div>
//         </header>
//       </div>

//       {showsidebar === true ? (
//         <div className="mobile_sidebar">
//           <div className="mobile_sidebar_body">
//             <div className="innermobile_sidebar_body">
//               <div className="header_container_2">
//                 <ul>
//                   <li style={{ maxHeight: "40px" }}>
//                     <NavLink to={"/"} activeClassName="active">
//                       Home
//                     </NavLink>
//                   </li>

//                   <li
//                     style={{
//                       marginLeft: "1rem",
//                       maxHeight:
//                         showproductMenuabout === true ? "185px" : "30px",
//                     }}
//                   >
//                     <div
//                       style={{ fontWeight: "600" }}
//                       onClick={() => {
//                         setShowproductMenuabout(!showproductMenuabout);
//                       }}
//                     >
//                       About Us
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         width="1em"
//                         height="1em"
//                         viewBox="0 0 16 16"
//                       >
//                         <path
//                           fill="currentColor"
//                           fill-rule="non-zero"
//                           d="M13.069 5.157L8.384 9.768a.546.546 0 0 1-.768 0L2.93 5.158a.55.55 0 0 0-.771 0a.53.53 0 0 0 0 .759l4.684 4.61a1.65 1.65 0 0 0 2.312 0l4.684-4.61a.53.53 0 0 0 0-.76a.55.55 0 0 0-.771 0"
//                         />
//                       </svg>
//                     </div>

//                     {showproductMenuabout === true ? (
//                       <div className="product_dropdown_mobile">
//                         <div className="innerproduct_dropdown_mobile">
//                           <ul style={{ gap: "0" }}>
//                             <HashLink
//                               to={`/about-us#chairman-message`}
//                               style={{
//                                 width: "100%",
//                                 padding: "0",
//                                 textTransform: "capitalize",
//                               }}
//                               onClick={() => setShowsidebar(false)}
//                             >
//                               <li
//                                 style={{
//                                   height: "100%",
//                                   textTransform: "capitalize",
//                                 }}
//                               >
//                                 Chairman’s Message
//                                 <svg
//                                   xmlns="http://www.w3.org/2000/svg"
//                                   width="1em"
//                                   height="1em"
//                                   viewBox="0 0 16 16"
//                                 >
//                                   <path
//                                     fill="currentColor"
//                                     fill-rule="non-zero"
//                                     d="M13.069 5.157L8.384 9.768a.546.546 0 0 1-.768 0L2.93 5.158a.55.55 0 0 0-.771 0a.53.53 0 0 0 0 .759l4.684 4.61a1.65 1.65 0 0 0 2.312 0l4.684-4.61a.53.53 0 0 0 0-.76a.55.55 0 0 0-.771 0"
//                                   />
//                                 </svg>
//                               </li>
//                             </HashLink>

//                             <HashLink
//                               to={`/about-us#our-story`}
//                               style={{
//                                 width: "100%",
//                                 padding: "0",
//                                 textTransform: "capitalize",
//                               }}
//                               onClick={() => setShowsidebar(false)}
//                             >
//                               <li
//                                 style={{
//                                   height: "100%",
//                                   textTransform: "capitalize",
//                                 }}
//                               >
//                                 Our Story
//                                 <svg
//                                   xmlns="http://www.w3.org/2000/svg"
//                                   width="1em"
//                                   height="1em"
//                                   viewBox="0 0 16 16"
//                                 >
//                                   <path
//                                     fill="currentColor"
//                                     fill-rule="non-zero"
//                                     d="M13.069 5.157L8.384 9.768a.546.546 0 0 1-.768 0L2.93 5.158a.55.55 0 0 0-.771 0a.53.53 0 0 0 0 .759l4.684 4.61a1.65 1.65 0 0 0 2.312 0l4.684-4.61a.53.53 0 0 0 0-.76a.55.55 0 0 0-.771 0"
//                                   />
//                                 </svg>
//                               </li>
//                             </HashLink>

//                             <HashLink
//                               to={`/about-us#vision-&-mission`}
//                               style={{
//                                 width: "100%",
//                                 padding: "0",
//                                 textTransform: "capitalize",
//                               }}
//                               onClick={() => setShowsidebar(false)}
//                             >
//                               <li
//                                 style={{
//                                   height: "100%",
//                                   textTransform: "capitalize",
//                                 }}
//                               >
//                                 Vision & Mission
//                                 <svg
//                                   xmlns="http://www.w3.org/2000/svg"
//                                   width="1em"
//                                   height="1em"
//                                   viewBox="0 0 16 16"
//                                 >
//                                   <path
//                                     fill="currentColor"
//                                     fill-rule="non-zero"
//                                     d="M13.069 5.157L8.384 9.768a.546.546 0 0 1-.768 0L2.93 5.158a.55.55 0 0 0-.771 0a.53.53 0 0 0 0 .759l4.684 4.61a1.65 1.65 0 0 0 2.312 0l4.684-4.61a.53.53 0 0 0 0-.76a.55.55 0 0 0-.771 0"
//                                   />
//                                 </svg>
//                               </li>
//                             </HashLink>
//                           </ul>
//                         </div>
//                       </div>
//                     ) : (
//                       ""
//                     )}
//                   </li>

//                   <li
//                     style={{
//                       marginLeft: "1rem",
//                       maxHeight: showproductMenu === true ? "185px" : "30px",
//                     }}
//                   >
//                     <div
//                       style={{ fontWeight: "600" }}
//                       onClick={() => {
//                         setShowproductMenu(!showproductMenu);
//                       }}
//                     >
//                       Product
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         width="1em"
//                         height="1em"
//                         viewBox="0 0 16 16"
//                       >
//                         <path
//                           fill="currentColor"
//                           fill-rule="non-zero"
//                           d="M13.069 5.157L8.384 9.768a.546.546 0 0 1-.768 0L2.93 5.158a.55.55 0 0 0-.771 0a.53.53 0 0 0 0 .759l4.684 4.61a1.65 1.65 0 0 0 2.312 0l4.684-4.61a.53.53 0 0 0 0-.76a.55.55 0 0 0-.771 0"
//                         />
//                       </svg>
//                     </div>

//                     {showproductMenu === true ? (
//                       <div className="product_dropdown_mobile">
//                         <div className="innerproduct_dropdown_mobile">
//                           <ul style={{ gap: "0" }}>
//                             {category.map((items) => {
//                               return (
//                                 <>
//                                   <NavLink
//                                     to={`/${items.slug}`}
//                                     style={{ width: "100%", padding: "0" }}
//                                   >
//                                     <li style={{ height: "100%" }}>
//                                       {items.title}
//                                       <svg
//                                         xmlns="http://www.w3.org/2000/svg"
//                                         width="1em"
//                                         height="1em"
//                                         viewBox="0 0 16 16"
//                                       >
//                                         <path
//                                           fill="currentColor"
//                                           fill-rule="non-zero"
//                                           d="M13.069 5.157L8.384 9.768a.546.546 0 0 1-.768 0L2.93 5.158a.55.55 0 0 0-.771 0a.53.53 0 0 0 0 .759l4.684 4.61a1.65 1.65 0 0 0 2.312 0l4.684-4.61a.53.53 0 0 0 0-.76a.55.55 0 0 0-.771 0"
//                                         />
//                                       </svg>
//                                     </li>
//                                   </NavLink>
//                                 </>
//                               );
//                             })}
//                           </ul>
//                         </div>
//                       </div>
//                     ) : (
//                       ""
//                     )}
//                   </li>
//                   <li style={{ maxHeight: "40px" }}>
//                     <NavLink to={"/blogs"} activeClassName="active">
//                       Blogs
//                     </NavLink>
//                   </li>


//                   <li style={{ maxHeight: "40px" }}>
//                     <NavLink to={"/locate-us"} activeClassName="active">
//                       Locate Us
//                     </NavLink>
//                   </li>
//                 </ul>




//                 <div className="header_button_container">
//                 <a
//                   href="/assets/images/Brochure_alu_empire.pdf"
//                   download
//                   style={{ textDecoration: "none" }}
//                 >
//                   <button className="header_button1">
//                     <img
//                       src="/assets/images/Home/icons/brochure_icon.png"
//                       alt="Brochure icon"
//                     />
//                     <span>Brochure</span>
//                   </button>
//                 </a>

//                   <button className="header_button1" onClick={handleOpen} >
//                     <img
//                       src="/assets/images/Home/icons/enqury_icon.png"
//                       alt="Brochure icon"
//                     />
//                     <span>Enquire</span>
//                   </button>

//                 {/* <a
//                   href="tel:90026 90068"
//                   style={{ color: "white", textDecoration: "none" }}
//                 >
//                   <button className="header_button2">
//                     <img
//                       src="/assets/images/Home/icons/header_phone_icon.png"
//                       alt="Brochure icon"
//                     />
//                     <span>90026 90068</span>
//                   </button>
//                 </a> */}
//               </div>


//               </div>
//             </div>
//           </div>
//         </div>
//       ) : (
//         ""
//       )}





//       {/* modal 1 start from here */}
//       <Modal
//         open={openmodal}
//         onClose={handleClosemodal}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <Box sx={style}>
//           <div className="innergetin_touch_right_container shadow">
//             <Text
//               style={{ color: "#fff", fontSize: "1.3rem" }}
//               text={"CUSTOMER COMPLAINTS"}
//             />
//             <div className="form_input_feild_container">
//               <div className="pricing_container">
//                 <div className="input_feild_title">
//                   <p style={{ marginBottom: "0", color: "#fff" }}>Name</p>
//                 </div>
//                 <div className="input_feild">
//                   <input
//                     className="contact_form_input_feild"
//                     type="text"
//                     placeholder="Enter your name"
//                     required
//                     onChange={(e) => setCustomerName(e.target.value)}
//                   />
//                 </div>
//               </div>
//               <div className="pricing_container">
//                 <div className="input_feild_title">
//                   <p style={{ marginBottom: "0", color: "#fff" }}>
//                     Phone Number
//                   </p>
//                 </div>
//                 <div className="input_feild">
//                   <input
//                     className="contact_form_input_feild"
//                     type="text"
//                     placeholder="Enter your phone number"
//                     required
//                     onChange={(e) => setPhoneNumber(e.target.value)}
//                   />
//                 </div>
//               </div>
//               <div className="pricing_container">
//                 <div className="input_feild_title">
//                   <p style={{ marginBottom: "0", color: "#fff" }}>
//                     I’m looking for
//                   </p>
//                 </div>
//                 <div className="input_feild">
//                   <select
//                     name="requiredService"
//                     id="requiredService"
//                     required
//                     onChange={(e) => setRequiredService(e.target.value)}
//                   >
//                     <option value="uPVC">uPVC</option>
//                     <option value="ALU Home Interior">ALU Home Interior</option>
//                     <option value="Commercial partition system">
//                       Commercial partition system
//                     </option>
//                     <option value="other">other</option>
//                   </select>
//                 </div>
//               </div>

//               {required_service === "other" ? (
//                 <div className="pricing_container">
//                   <div className="input_feild_title">
//                     <p style={{ marginBottom: "0", color: "#fff" }}></p>
//                   </div>
//                   <div className="input_feild">
//                     <input
//                       className="contact_form_input_feild"
//                       type="text"
//                       placeholder="Please specify"
//                       required
//                       onChange={(e) => setotherDescription(e.target.value)}
//                     />
//                   </div>
//                 </div>
//               ) : null}

//               <div className="pricing_container">
//                 <div className="input_feild_title">
//                   <p style={{ marginBottom: "0", color: "#fff" }}>Email ID</p>
//                 </div>
//                 <div className="input_feild">
//                   <input
//                     type="email"
//                     className="contact_form_input_feild"
//                     placeholder="Enter your email"
//                     required
//                     onChange={(e) => setEmail(e.target.value)}
//                   />
//                 </div>
//               </div>

//               <button className="form_btn" onClick={formSubmit}>
//                 Submit
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   width="1em"
//                   height="1em"
//                   viewBox="0 0 15 15"
//                 >
//                   <path
//                     fill="none"
//                     stroke="currentColor"
//                     d="m13.5 7.5l-4-4m4 4l-4 4m4-4H1"
//                   />
//                 </svg>
//               </button>

//               {error && (
//                 <Snackbar
//                   open={open}
//                   autoHideDuration={3000}
//                   onClose={handleClose}
//                   message={error}
//                 />
//               )}
//               {successmessege && (
//                 <Snackbar
//                   open={open}
//                   autoHideDuration={3000}
//                   onClose={handleClose}
//                   message={successmessege}
//                 />
//               )}
//             </div>
//           </div>
//         </Box>
//       </Modal>
//       {/* modal 1 close from here */}

//       {/* modal 2 start from here */}
//       <Modal
//         open={openmodal2}
//         onClose={handleClosemodal2}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <Box sx={style}>
//           <div className="innergetin_touch_right_container shadow">
//             <Text
//               style={{ color: "#fff", fontSize: "1.3rem" }}
//               text={"Reach The Business Head"}
//             />
//             <div className="form_input_feild_container">
//               <div className="pricing_container">
//                 <div className="input_feild_title">
//                   <p style={{ marginBottom: "0", color: "#fff" }}>Name</p>
//                 </div>
//                 <div className="input_feild">
//                   <input
//                     className="contact_form_input_feild"
//                     type="text"
//                     placeholder="Enter your name"
//                     required
//                     onChange={(e) => setCustomerName(e.target.value)}
//                   />
//                 </div>
//               </div>
//               <div className="pricing_container">
//                 <div className="input_feild_title">
//                   <p style={{ marginBottom: "0", color: "#fff" }}>
//                     Phone Number
//                   </p>
//                 </div>
//                 <div className="input_feild">
//                   <input
//                     className="contact_form_input_feild"
//                     type="text"
//                     placeholder="Enter your phone number"
//                     required
//                     onChange={(e) => setPhoneNumber(e.target.value)}
//                   />
//                 </div>
//               </div>
//               <div className="pricing_container">
//                 <div className="input_feild_title">
//                   <p style={{ marginBottom: "0", color: "#fff" }}>
//                     I’m looking for
//                   </p>
//                 </div>
//                 <div className="input_feild">
//                   <select
//                     name="requiredService"
//                     id="requiredService"
//                     required
//                     onChange={(e) => setRequiredService(e.target.value)}
//                   >
//                     <option value="uPVC">uPVC</option>
//                     <option value="ALU Home Interior">ALU Home Interior</option>
//                     <option value="Commercial partition system">
//                       Commercial partition system
//                     </option>
//                     <option value="other">other</option>
//                   </select>
//                 </div>
//               </div>

//               {required_service === "other" ? (
//                 <div className="pricing_container">
//                   <div className="input_feild_title">
//                     <p style={{ marginBottom: "0", color: "#fff" }}></p>
//                   </div>
//                   <div className="input_feild">
//                     <input
//                       className="contact_form_input_feild"
//                       type="text"
//                       placeholder="Please specify"
//                       required
//                       onChange={(e) => setotherDescription(e.target.value)}
//                     />
//                   </div>
//                 </div>
//               ) : null}

//               <div className="pricing_container">
//                 <div className="input_feild_title">
//                   <p style={{ marginBottom: "0", color: "#fff" }}>Email ID</p>
//                 </div>
//                 <div className="input_feild">
//                   <input
//                     type="email"
//                     className="contact_form_input_feild"
//                     placeholder="Enter your email"
//                     required
//                     onChange={(e) => setEmail(e.target.value)}
//                   />
//                 </div>
//               </div>

//               <button className="form_btn" onClick={formSubmit}>
//                 Submit
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   width="1em"
//                   height="1em"
//                   viewBox="0 0 15 15"
//                 >
//                   <path
//                     fill="none"
//                     stroke="currentColor"
//                     d="m13.5 7.5l-4-4m4 4l-4 4m4-4H1"
//                   />
//                 </svg>
//               </button>

//               {error && (
//                 <Snackbar
//                   open={open}
//                   autoHideDuration={3000}
//                   onClose={handleClose}
//                   message={error}
//                 />
//               )}
//               {successmessege && (
//                 <Snackbar
//                   open={open}
//                   autoHideDuration={3000}
//                   onClose={handleClose}
//                   message={successmessege}
//                 />
//               )}
//             </div>
//           </div>
//         </Box>
//       </Modal>
//       {/* modal 2 close from here */}


//     </>
//   );
// };
// export { MobileHeader };















import React, { useEffect, useState } from "react";
import { Img } from "../subComponents/img";
import { NavLink } from "react-router-dom";
import { Text } from "../subComponents/text";
import { baseurl } from "./baseurl";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import SearchBar from "./searchingBar";


import Modal from "@mui/material/Modal";
import Snackbar from "@mui/material/Snackbar";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "max-content",
  bgcolor: "background.paper",
  // border: '2px solid #000',
  borderRadius: "2rem",
  boxShadow: 24,
  // p: 4,
};


const MobileHeader = () => {
  const [showsidebar, setShowsidebar] = useState(false);
  const [showproductMenu, setShowproductMenu] = useState(false);
  const [showproductMenuabout, setShowproductMenuabout] = useState(false);

  const [category, setCategorys] = useState([]);
  const [issreaching, setIssearching] = useState(false);



  
  const [openmodal, setOpenmodal] = React.useState(false);
  const handleClosemodal = () => setOpenmodal(false);
  const handleOpen = () => setOpenmodal(true);


  const [customer_name, setCustomerName] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [required_service, setRequiredService] = useState("uPVC");
  const [otherdescription, setOtherDescription] = useState("");

  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const validateForm = () => {
    if (!customer_name) {
      setError("Name is required");
      handleClick();
      return false;
    }
    if (!phone_number) {
      setError("Phone number is required");
      handleClick();
      return false;
    }
    // if (!email) {
    //   setError("Email is required");
    //   handleClick();
    //   return false;
    // }
    if (required_service === "other" && !otherdescription) {
      setError("Please specify the service");
      handleClick();
      return false;
    }
    setError(""); // Clear any previous error
    return true;
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    // Allow only numeric values
    if (/^\d*$/.test(value)) {
      if (value.length <= 15) {
        setPhoneNumber(value);
        setPhoneNumberError("");
      } else {
        setPhoneNumberError("Cannot enter more than 15 characters");
        handleClick();
      }
    } else {
      setPhoneNumberError("Only numeric values are allowed");
      handleClick();
    }
  };

  const formSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      const response = await fetch(`${baseurl}/api/contact-us`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customer_name,
          phone_number,
          email,
          required_service,
          otherdescription,
        }),
      });

      const data = await response.json();
      if (data.success === "1") {
        console.log(data.message);
        setSuccessMessage(data.message);
        clearForm();
        handleClick();
      } else {
        console.error("Failed to submit data:", data.message);
        setError(data.message);
        handleClick();
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      setError("An error occurred. Please try again.");
      handleClick();
    }
  };

  const clearForm = () => {
    setCustomerName("");
    setPhoneNumber("");
    setEmail("");
    setRequiredService("uPVC");
    setOtherDescription("");
  };



  // api fetching start from here

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const response = await fetch(`${baseurl}/api/category`, {
          method: "GET",
        });

        const data = await response.json();
        if (data.success === "1") {
          setCategorys(data.data);
        } else {
          console.error("Failed to fetch blog data:", data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchCategory();
  }, []);

  // api fetching close from here


  return (
    <>
      <div className="outer_mobile_header">
        <header className="mobile_header shadow-sm">
          <div className="mobile_header_logo_container">
            <Link to={"/"}>
              <Img src={"/assets/images/logo.png"} alt={'site logo'} />
            </Link>
          </div>

          <div className="mobile_menu_container">
            <div> 
            <div className="">
                    <div className="search_container" onClick={()=>setIssearching(!issreaching)} >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ color: "#fff" }}
                        width="1.2rem"
                        height="1.2rem"
                        viewBox="0 0 15 15"
                      >
                        <path
                          fill="none"
                          stroke="currentColor"
                          d="m14.5 14.5l-4-4m-4 2a6 6 0 1 1 0-12a6 6 0 0 1 0 12Z"
                        />
                      </svg>
                    </div>
                  </div>
                 {
                  issreaching &&  <SearchBar/>
                 }
            </div>
            <div
              className="menu_bnt"
              onClick={() => {
                setShowsidebar(!showsidebar);
              }}
            >
              {showsidebar === true ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.8em"
                  height="1.8em"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.8em"
                  height="1.8em"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M3 18h18v-2H3zm0-5h18v-2H3zm0-7v2h18V6z"
                  />
                </svg>
              )}
            </div>
          </div>
        </header>
      </div>

      {showsidebar === true ? (
        <div className="mobile_sidebar">
          <div className="mobile_sidebar_body">
            <div className="innermobile_sidebar_body">
              <div className="header_container_2">
                <ul>
                  <li style={{ maxHeight: "40px" }}>
                    <NavLink to={"/"} activeClassName="active">
                      Home
                    </NavLink>
                  </li>

                  <li
                    style={{
                      marginLeft: "1rem",
                      maxHeight:
                        showproductMenuabout === true ? "185px" : "30px",
                    }}
                  >
                    <div
                      style={{ fontWeight: "600" }}
                      onClick={() => {
                        setShowproductMenuabout(!showproductMenuabout);
                      }}
                    >
                      About Us
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill="currentColor"
                          fill-rule="non-zero"
                          d="M13.069 5.157L8.384 9.768a.546.546 0 0 1-.768 0L2.93 5.158a.55.55 0 0 0-.771 0a.53.53 0 0 0 0 .759l4.684 4.61a1.65 1.65 0 0 0 2.312 0l4.684-4.61a.53.53 0 0 0 0-.76a.55.55 0 0 0-.771 0"
                        />
                      </svg>
                    </div>

                    {showproductMenuabout === true ? (
                      <div className="product_dropdown_mobile">
                        <div className="innerproduct_dropdown_mobile">
                          <ul style={{ gap: "0" }}>
                            <HashLink
                              to={`/about-us#chairman-message`}
                              style={{
                                width: "100%",
                                padding: "0",
                                textTransform: "capitalize",
                              }}
                              onClick={() => setShowsidebar(false)}
                            >
                              <li
                                style={{
                                  height: "100%",
                                  textTransform: "capitalize",
                                }}
                              >
                                Chairman’s Message
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="1em"
                                  height="1em"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fill="currentColor"
                                    fill-rule="non-zero"
                                    d="M13.069 5.157L8.384 9.768a.546.546 0 0 1-.768 0L2.93 5.158a.55.55 0 0 0-.771 0a.53.53 0 0 0 0 .759l4.684 4.61a1.65 1.65 0 0 0 2.312 0l4.684-4.61a.53.53 0 0 0 0-.76a.55.55 0 0 0-.771 0"
                                  />
                                </svg>
                              </li>
                            </HashLink>

                            <HashLink
                              to={`/about-us#our-story`}
                              style={{
                                width: "100%",
                                padding: "0",
                                textTransform: "capitalize",
                              }}
                              onClick={() => setShowsidebar(false)}
                            >
                              <li
                                style={{
                                  height: "100%",
                                  textTransform: "capitalize",
                                }}
                              >
                                Our Story
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="1em"
                                  height="1em"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fill="currentColor"
                                    fill-rule="non-zero"
                                    d="M13.069 5.157L8.384 9.768a.546.546 0 0 1-.768 0L2.93 5.158a.55.55 0 0 0-.771 0a.53.53 0 0 0 0 .759l4.684 4.61a1.65 1.65 0 0 0 2.312 0l4.684-4.61a.53.53 0 0 0 0-.76a.55.55 0 0 0-.771 0"
                                  />
                                </svg>
                              </li>
                            </HashLink>

                            <HashLink
                              to={`/about-us#vision-&-mission`}
                              style={{
                                width: "100%",
                                padding: "0",
                                textTransform: "capitalize",
                              }}
                              onClick={() => setShowsidebar(false)}
                            >
                              <li
                                style={{
                                  height: "100%",
                                  textTransform: "capitalize",
                                }}
                              >
                                Vision & Mission
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="1em"
                                  height="1em"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fill="currentColor"
                                    fill-rule="non-zero"
                                    d="M13.069 5.157L8.384 9.768a.546.546 0 0 1-.768 0L2.93 5.158a.55.55 0 0 0-.771 0a.53.53 0 0 0 0 .759l4.684 4.61a1.65 1.65 0 0 0 2.312 0l4.684-4.61a.53.53 0 0 0 0-.76a.55.55 0 0 0-.771 0"
                                  />
                                </svg>
                              </li>
                            </HashLink>
                          </ul>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </li>

                  <li
                    style={{
                      marginLeft: "1rem",
                      maxHeight: showproductMenu === true ? "185px" : "30px",
                    }}
                  >
                    <div
                      style={{ fontWeight: "600" }}
                      onClick={() => {
                        setShowproductMenu(!showproductMenu);
                      }}
                    >
                      Product
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill="currentColor"
                          fill-rule="non-zero"
                          d="M13.069 5.157L8.384 9.768a.546.546 0 0 1-.768 0L2.93 5.158a.55.55 0 0 0-.771 0a.53.53 0 0 0 0 .759l4.684 4.61a1.65 1.65 0 0 0 2.312 0l4.684-4.61a.53.53 0 0 0 0-.76a.55.55 0 0 0-.771 0"
                        />
                      </svg>
                    </div>

                    {showproductMenu === true ? (
                      <div className="product_dropdown_mobile">
                        <div className="innerproduct_dropdown_mobile">
                          <ul style={{ gap: "0" }}>
                            {category.map((items) => {
                              return (
                                <>
                                  <NavLink
                                    to={`/${items.slug}`}
                                    style={{ width: "100%", padding: "0" }}
                                  >
                                    <li style={{ height: "100%" }}>
                                      {items.title}
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="1em"
                                        height="1em"
                                        viewBox="0 0 16 16"
                                      >
                                        <path
                                          fill="currentColor"
                                          fill-rule="non-zero"
                                          d="M13.069 5.157L8.384 9.768a.546.546 0 0 1-.768 0L2.93 5.158a.55.55 0 0 0-.771 0a.53.53 0 0 0 0 .759l4.684 4.61a1.65 1.65 0 0 0 2.312 0l4.684-4.61a.53.53 0 0 0 0-.76a.55.55 0 0 0-.771 0"
                                        />
                                      </svg>
                                    </li>
                                  </NavLink>
                                </>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </li>
                  <li style={{ maxHeight: "40px" }}>
                    <NavLink to={"/blogs"} activeClassName="active">
                      Blogs
                    </NavLink>
                  </li>


                  <li style={{ maxHeight: "40px" }}>
                    <NavLink to={"/locate-us"} activeClassName="active">
                      Locate Us
                    </NavLink>
                  </li>
                </ul>




                <div className="header_button_container">
                <a
                  href="/assets/images/Brochure_alu_empire.pdf"
                  download
                  style={{ textDecoration: "none" }}
                >
                  <button className="header_button1">
                    <img
                      src="/assets/images/Home/icons/brochure_icon.png"
                      alt="Brochure icon"
                    />
                    <span>Brochure</span>
                  </button>
                </a>

                  <button className="header_button1" onClick={handleOpen} >
                    <img
                      src="/assets/images/Home/icons/enqury_icon.png"
                      alt="Brochure icon"
                    />
                    <span>Enquire</span>
                  </button>
              </div>


              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}





      {/* modal 1 start from here */}
      <Modal
        open={openmodal}
        onClose={handleClosemodal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="innergetin_touch_right_container shadow">
            <Text
              style={{ color: "#fff", fontSize: "1.3rem" }}
              text={"CUSTOMER COMPLAINTS"}
            />
          <div className="form_input_feild_container">
                <div className="pricing_container">
                  <div className="input_feild_title">
                    <p style={{ marginBottom: "0", color: "#fff" }}>Name</p>
                  </div>
                  <div className="input_feild">
                    <input
                      className="contact_form_input_feild"
                      type="text"
                      placeholder="Enter your name"
                      maxLength={35}
                      value={customer_name}
                      onChange={(e) => setCustomerName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="pricing_container">
                  <div className="input_feild_title">
                    <p style={{ marginBottom: "0", color: "#fff" }}>
                      Phone Number
                    </p>
                  </div>
                  <div className="input_feild">
                    <input
                      className="contact_form_input_feild"
                      type="text"
                      placeholder="Enter your phone number"
                      maxLength={13}
                      value={phone_number}
                      onChange={handlePhoneNumberChange}
                    />
                  </div>
                </div>
                <div className="pricing_container">
                  <div className="input_feild_title">
                    <p style={{ marginBottom: "0", color: "#fff" }}>
                      I’m looking for
                    </p>
                  </div>
                  <div className="input_feild">
                    <select
                      name="requiredService"
                      id="requiredService"
                      required
                      value={required_service}
                      onChange={(e) => setRequiredService(e.target.value)}
                    >
                      <option value="uPVC">uPVC</option>
                      <option value="ALU Home Interior">
                        ALU Home Interior
                      </option>
                      <option value="Commercial partition system">
                        Commercial partition system
                      </option>
                      <option value="other">other</option>
                    </select>
                  </div>
                </div>

                {required_service === "other" ? (
                  <div className="pricing_container">
                    <div className="input_feild_title">
                      <p style={{ marginBottom: "0", color: "#fff" }}></p>
                    </div>
                    <div className="input_feild">
                      <input
                        className="contact_form_input_feild"
                        type="text"
                        placeholder="Please specify"
                        maxLength={50}
                        value={otherdescription}
                        onChange={(e) => setOtherDescription(e.target.value)}
                      />
                    </div>
                  </div>
                ) : null}

                <div className="pricing_container">
                  <div className="input_feild_title">
                    <p style={{ marginBottom: "0", color: "#fff" }}>Email ID</p>
                  </div>
                  <div className="input_feild">
                    <input
                      type="email"
                      className="contact_form_input_feild"
                      placeholder="Enter your email"
                      maxLength={70}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>

                <button className="form_btn" onClick={formSubmit}>
                  Submit
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 15 15"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      d="m13.5 7.5l-4-4m4 4l-4 4m4-4H1"
                    />
                  </svg>
                </button>

                <Snackbar
                  open={open}
                  autoHideDuration={3000}
                  onClose={handleClose}
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                  <Alert onClose={handleClose} severity={error ? 'error' : 'success'}>
                    {error || successMessage || phoneNumberError}
                  </Alert>
                </Snackbar>
              </div>
          </div>
        </Box>
      </Modal>
      {/* modal 1 close from here */}

    </>
  );
};
export { MobileHeader };
