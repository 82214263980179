import React, { useEffect } from "react";
import BlogSlider from "./slider";
import { Heading } from "../../../../subComponents/Heading";
import { Subheading } from "../../../../subComponents/subHeading";

import AOS from "aos";
import "aos/dist/aos.css";

const Blogs=()=>{

    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);

    return(
        <>
         <div className="blogs_slider_container" >
            <div className="blog_heading" >
                <Subheading title={"OUR RECENT NEWS"} style={{color:"#FA853D", fontSize:"1rem",textAlign:"center"}} />
                <Heading title={" Recent Blog "} />
            </div>
            <BlogSlider/>
         </div> 
        </>
    );
}
export {Blogs};













