import React, { useEffect, useState } from "react";
import Header from "../../CommonComponents/header";
import { Footer } from "../../CommonComponents/footer";
import { MobileHeader } from "../../CommonComponents/mobileHeader";
import { BlogHeroSection } from "../BlogsPage/Components/blogHeroSection";
import AOS from "aos";
import "aos/dist/aos.css";
import { baseurl } from "../../CommonComponents/baseurl";
import { NavLink } from "react-router-dom";
import { Img } from "../../subComponents/img";
import { Subheading } from "../../subComponents/subHeading";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import {
  Grid,
  Skeleton,
} from "@mui/material";


const RecentProjectPage = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const [currentbanner, setCurrentBanner] = useState(null);

  useEffect(() => {
    const fetchBanner = async () => {
      try {
        const response = await fetch(`${baseurl}/api/our-projects`, {
          method: "GET",
        });

        const data = await response.json();
        if (data.success === "1") {
          setCurrentBanner(data.data);
        } else {
          console.error("Failed to currentbanner data:", data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchBanner();
  }, []);

  useEffect(() => {
    console.log("currentbanner:", currentbanner);
  }, [currentbanner]);

  return (
    <>
      <Header />
      <MobileHeader />
      <div className="desktop_header_spacer"></div>
      <BlogHeroSection
        pagetitle={"RECENT PROJECTS"}
        pagedescription={
          "See what we've done: innovation, excellence, and results that inspire"
        }
      />

      <div className="recent_project_page_section">
        {currentbanner === null ? (
          <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {Array.from(new Array(8)).map((_, index) => (
            <Grid item xs={6} sm={4} md={4} lg={4} key={index}>
              <Skeleton variant="rectangular" sx={{ maxWidth: 350, maxHeight: "300px", minHeight: "300px", mx: "auto" }} />
              <Skeleton variant="text" style={{height:"40px", width:"200px", margin:"auto"}}  ></Skeleton>
            </Grid>
          ))}
        </Grid>
        ) : (
          currentbanner.map((items) => {
            return (
              <>
                <div
                  className="recent_projects_all"
                  data-aos="flip-left"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="2000"
                >
                  <Img src={items.image} alt={'recent project'} style={{ zIndex: "-1" }} />

                  <NavLink
                          to={`/recent-project/${items.slug}`}
                          style={{ textDecoration: "none" }}
                        >
                          <div className="recent_project_button">
                          <Subheading
                            title={items.title}
                            style={{
                              textTransform: "uppercase",
                            }}
                          />
                          </div>
                        </NavLink>
                </div>
              </>
            );
          })
        )}
      </div>
      <div style={{ height: "3rem" }}></div>
      <Footer />
    </>
  );
};
export default RecentProjectPage;
