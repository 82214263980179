import React, { useEffect, useState } from "react";
import Header from "../../../../CommonComponents/header";
import { Footer } from "../../../../CommonComponents/footer";
import { baseurl } from "../../../../CommonComponents/baseurl";
import { useParams } from "react-router-dom";
import { Img } from "../../../../subComponents/img";
import { Heading } from "../../../../subComponents/Heading";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { MobileHeader } from "../../../../CommonComponents/mobileHeader";


const RecentProject = () => {
  const [currentproject, setCurrentProject] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    const fetchrecentProject = async () => {
      const formData = new FormData();
      formData.append("slug", slug);

      try {
        const response = await fetch(`${baseurl}/api/project-details`, {
          method: "POST",
          body: formData,
        });

        const data = await response.json();
        if (data.success === "1") {
          setCurrentProject(data.data);
        } else {
          console.error("Failed to recent project data:", data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchrecentProject();
  }, []);

  useEffect(() => {
    console.log("currentproject:", currentproject);
  }, [currentproject]);

  return (
    <>
      <Header />
      <MobileHeader/>
      <div className="desktop_header_spacer"></div>
      {
        currentproject===null?
        <Box sx={{ display: "flex" }}>
        <CircularProgress style={{ color: "#000", margin: "auto" }} />
      </Box>
        :
        <section className="recent_project_hero_section">
        {/* <div style={{height:"380px", width:"100%"}} >
          <Img src={currentproject.image} style={{objectFit:"cover"}} />
        </div> */}
        <Img src={currentproject.image2} alt={currentproject.image2} style={{ objectFit: "cover" }} height={"100%"} width={"100%"}  />

        <div className="commercial_description" >
        <br />
        <Heading title={currentproject.title} />
        <br />
          <p
            dangerouslySetInnerHTML={{ __html: currentproject.description }}
          ></p>
        </div>
      </section>
      }

      <Footer />
    </>
  );
};
export default RecentProject;
