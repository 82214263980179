import React, { useEffect, useState } from "react";
import { Img } from "../../../subComponents/img";
import { Heading } from "../../../subComponents/Heading";
import { NavLink, useParams } from "react-router-dom";
import { baseurl } from "../../../CommonComponents/baseurl";
import { Skeleton,Grid } from "@mui/material";

const ProductCategoryHeroSection = ({
  title,
  content,
  show,
  showinterior,
  showpvc,
  subcategorylist,
  categoryslug,
  bannerImage
}) => {
  const [currentcategory, setCurrentcategory] = useState([]);
  const { slug } = useParams();

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const formData = new FormData();
        formData.append({ slug: slug, product_page: 1 });

        const response = await fetch(`${baseurl}/api/product`, {
          method: "POST",
          body: formData,
        });

        const data = await response.json();
        if (data.success === "1") {
          setCurrentcategory(data.data);
        } else {
          console.error("Failed to fetch blog data:", data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchCategory();
  }, [slug]);

  useEffect(() => {
    console.log(subcategorylist);
  }, [subcategorylist]);


  
  return (
    <>
      <section className="upvc_hero_section">
        {
          bannerImage === null?
          <Skeleton variant="rectangle" height={"100%"} width={"100%"} ></Skeleton>
          :
          <Img src={bannerImage} alt={"Banner.png"} />
        }
        <div className="upvc_overlay">
          <div className="innerupvc_overlay">
            <div className="upvc_content">
              <Heading content={title}  />
              {/* <div dangerouslySetInnerHTML={{ __html: content }} ></div> */}
              <p>{content}</p>
              {/* <button className="get_started_btn">Get Started</button> */}
            </div>
          </div>
        </div>
      </section>

      <section className="product_hero_section_footer">
        <div className="product_hero_footer">
          {subcategorylist.length === 0?
                  <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  {Array.from(new Array(4)).map((_, index) => (
                    <Grid item xs={12} sm={6} md={3} lg={3} key={index}>
                      <Skeleton variant="rectangular" sx={{ maxWidth: 300, maxHeight: "100px", minHeight: "100px", mx: "auto" }} />
                    </Grid>
                  ))}
                </Grid>:
          subcategorylist.map((items) => {
            return (
              <NavLink to={(items.type)==="Product"?`/${categoryslug}/${items.slug}`:`/accessories/${items.slug}`}>
                <div className="accessories_container">
                  <div className="seting_icon_container">
                    <Img
                      src={items.image}
                      alt={"setting icon"}
                    />
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: items.title }}></div>
                </div>
              </NavLink>
            );
          })}
        </div>
      </section>
    </>
  );
};
export { ProductCategoryHeroSection };
