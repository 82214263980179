
import React from "react";
import { useRoutes } from "react-router-dom";
import AboutUspage from "../Pages/AboutUsPage/aboutUs";
import ContactUsPage from "../Pages/ContactUsPage/contactUsPage";
import BlogsPage from "../Pages/BlogsPage/blogsPage";
import ProductPage from "../Pages/ProductPage/productPage";
import HomePage from "../Pages/HomePage/homePage";
import { GlassPartitionPage } from "../Pages/ProductPage/productspages/glassPartitionPage";
import { UpvcProductPage } from "../Pages/ProductPage/productspages/upvcproduct";
import { HomeInterior } from "../Pages/ProductPage/productspages/HomeInterior/homeInterior";
import { TermConditionspage } from "../Pages/TermsCondition/terms&ConditionsPage";
import { PravicyPolicy } from "../Pages/TermsCondition/pravicyPolicy";
import useScrollToTop from "../CommonComponents/useScrolletoTop";
import { BlogDetailsPage } from "../Pages/BlogsPage/BlogDetailsPage";
import UpvcVariantPage from "../Pages/ProductPage/productspages/UPVCpages/upvcVariantPage";
import { ProductDetailsPage } from "../Pages/ProductPage/productspages/SingleProducts/productDetailspage";
import UpvcDoorPage from "../Pages/ProductPage/productspages/UPVCpages/upvcDoorPage";
import UpvcDoorWindowAccessrios from "../Pages/ProductPage/productspages/UPVCpages/upvcDoorWindowAccessories";
import GlassPartition from "../Pages/ProductPage/productspages/commercialPartialSystem/commercialPartionSystem";
import ProfileAndAccessories from "../Pages/ProductPage/productspages/commercialPartialSystem/profileAndAccessories";
import AccessoriesShopPage from "../Pages/ProductPage/productspages/SingleProducts/accessoriesShopPage";
import RecentProject from "../Pages/ProductPage/productspages/SingleProducts/recentProject";
import AccessoriesPage from "../Pages/ProductPage/productspages/SingleProducts/accessoriesPage";
import RecentProjectPage from "../Pages/RecentProjectPage/recentProjectPage";
import Testimonial from "../Pages/Testimonial/testimonial";

function ProjectRoutes() {
    useScrollToTop();

    const elements = useRoutes([
        {
            path: "/", element: <HomePage
            title="Best upvc door and window | glass shower cubical | upvc aluminium door and window"
            description="Elevate Your Space with best UPVC Doors and Windows. Discover our range of stylish upvc aluminium door and window and energy-efficient solutions for homes and businesses"
            keyword="Best upvc window and door, Glass wall office partition, glass shower cubical, interior designer in faridabad, Coloured upvc window and door, best pvc door company, upvc window sliding door, best upvc aluminium door and window, best upvc door in faridabad"
            />
        },
        {
            path: "/about-us", element: <AboutUspage
            title="About us | Best pvc door company | upvc window sliding door | best upvc door in faridabad"
            description="We are the best pvc door company and we offers a range of high-quality UPVC doors and windows designed to enhance your home's aesthetics and functionality."
            keyword="Best upvc window and door, Glass wall office partition, glass shower cubical, interior designer in faridabad, Coloured upvc window and door, best pvc door company, upvc window sliding door, best upvc aluminum door and window, best upvc door in faridabad"
            />
        },
        { path: "/locate-us", element: <ContactUsPage
            title="Alu empire | locate us"
            description="If you have comments, queries, or questions about home interiors and upvc window and door, please call us on 90026 90068 and speak to our design experts."
            keyword="Alu empire, contact us, commercial partition system, office space partition, glass wall office partition,  coloured upvc door and window, type of upvc door and window, upvc window sliding door, upvc window and door, Aluminium Skirting, led skirting, Best upvc window and door, Glass wall office partition, glass shower cubical, interior designer in faridabad, best pvc door company, best upvc aluminum door and window, best upvc door in faridabad"
             /> },
        {
            path: "/blogs", element: <BlogsPage
                title="Blog | Aluminium Skirting | Led skirting | Aluminium Shower cubicle | Interior designer faridabad"
                description="Best upvc window and door, Glass wall office partition, glass shower cubical, interior designer in faridabad, Coloured upvc window and door, best pvc door company, upvc window sliding door, best upvc aluminum door and window, best upvc door in faridabad"
                keyword="Alu Empire provides you the best aluminium skirting and led skirting freedom to create custom doors that perfectly complement the aesthetic and style of your spaces"
            />
        },
        { path: "/products/:slug", element: <ProductDetailsPage /> },
        { path: "/upvc", element: <UpvcProductPage /> },
        { path: "/:slug", element: <HomeInterior /> },
        { path: "/terms-&-Condition", element: <TermConditionspage /> },
        { path: "/privacy-policy", element: <PravicyPolicy /> },
        { path: "/blog-details-page/:slug", element: <BlogDetailsPage /> },
        { path: "/:slug/:slug", element: <UpvcVariantPage /> },
        { path: "/upvc_door", element: <UpvcDoorPage /> },
        { path: "/upvc-door-window-accessries", element: <UpvcDoorWindowAccessrios /> },
        { path: "/accessories-shop/:slug", element: <AccessoriesShopPage /> },
        { path: "/accessories/:slug", element: <AccessoriesPage /> },
        { path: "/recent-project/:slug", element: <RecentProject /> },
        { path: "/all-recent-project", element: <RecentProjectPage /> },
        { path: "/testimonial", element: <Testimonial /> }
    ]);

    return elements;

}
export default ProjectRoutes;