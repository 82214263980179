import React, { useEffect, useState } from "react";
import { Text } from "../../../../subComponents/text";
import { Img } from "../../../../subComponents/img";

import AOS from "aos";
import "aos/dist/aos.css";

const Clientcard = ({ pharagraph, clientname, designation, imageurl }) => {
  const [altimage, setAtlimage] = useState("");
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  useEffect(()=>{
    if(imageurl){
      var url = imageurl.split('/');
      var imagenamelength = url.length;
      var imagename = imageurl.split('/')[imagenamelength-1]
      // console.log(imagename);
      setAtlimage(imagename);
    }
  })

  return (
    <>
      <div
        className="client_card shadow"
        data-aos="flip-left"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="2000"
      >
        <div className="client_card_images">
          <Img src={imageurl} alt={altimage ?? altimage} />
        </div>
        <Text text={pharagraph} className={"review_pharagraph"} />

        <Text text={clientname} style={{ marginBottom: "0px" }} />
        <div
          style={{
            margin: "0px",
            padding: "0px",
            fontSize: "0.795rem",
            fontWeight: "600",
          }}
        >
          {designation}
        </div>
      </div>

      {/* <div
        className="client_card shadow"
        data-aos="flip-left"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="2000"
      >
        <Text
          text={pharagraph}
          className={"review_pharagraph"}
        />
        <div className="client_card_images">
          <Img src={imageurl} />
        </div>
        <Text text={clientname} style={{marginBottom:"0px"}} />
        <div style={{margin:"0px",padding:"0px",fontSize:"0.795rem", fontWeight:"600"}}>{designation}</div>
      </div> */}
    </>
  );
};
export { Clientcard };
