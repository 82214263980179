import React from "react";
import Header from "../../CommonComponents/header";
import { Footer } from "../../CommonComponents/footer";
import { AboutHeroSection } from "./Components/aboutHeroSection";
import { ChairManMessageSection } from "./Components/chairManMessageSection";
import { StorySection } from "./Components/storySection";
import { VisionMissionSection } from "./Components/vision&missionSection";
import { MobileHeader } from "../../CommonComponents/mobileHeader";
import { Helmet } from "react-helmet";
import { MetaTags } from "../../Seo/HelmetComponent";


function AboutUspage({ title, description, keyword }) {
    return (
        <>
            <Helmet>
                <MetaTags title={title} description={description} keyword={keyword} />
                {/* OG Tag:- */}

                <meta property="og:title" content="About us | Alu Empire" />
                <meta property="og:site_name" content="About us | Alu Empire" />
                <meta property="og:url" content="https://www.aluempire.com/about-us" />
                <meta property="og:description" content="We are the best pvc door company and we offers a range of high-quality UPVC doors and windows designed to enhance your home's aesthetics and functionality." />
                <meta property="og:type" content="" />
                <meta property="og:image" content="https://www.aluempire.com/assets/images/Alu%20Empire%20Logo.png" />


                {/* // Twitter Card:- */}

                <meta name="twitter:card" content="summary" />
                <meta name="twitter:site" content="@About Us | Alu Empire" />
                <meta name="twitter:title" content="@About Us | Alu Empire" />
                <meta name="twitter:description" content="We are the best pvc door company and we offers a range of high-quality UPVC doors and windows designed to enhance your home's aesthetics and functionality." />
                <meta name="twitter:image" content="https://www.aluempire.com/assets/images/Alu%20Empire%20Logo.png" />
            </Helmet>
            <Header />
            <MobileHeader />
            <div className="mobile_header_spacer"></div>
            <div className="desktop_header_spacer"></div>
            <AboutHeroSection />
            <div id="chairman-message"></div>
            <ChairManMessageSection />
            <div id="our-story"></div>
            <StorySection />
            <div id="vision-&-mission"></div>
            <VisionMissionSection />
            <Footer />
        </>
    )
}
export default AboutUspage;









