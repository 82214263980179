import { Subheading } from "../../../../subComponents/subHeading";
import { Img } from "../../../../subComponents/img";
import { Text } from "../../../../subComponents/text";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Header from "../../../../CommonComponents/header";
import { MobileHeader } from "../../../../CommonComponents/mobileHeader";
import { Footer } from "../../../../CommonComponents/footer";
import { SingleProduct } from "./singleProducts";
import UpvcVariantBannerSlider from "../../Components/upvcvariantbannerSlider";
import { useParams } from "react-router-dom";
import { baseurl } from "../../../../CommonComponents/baseurl";
import AOS from "aos";
import "aos/dist/aos.css";
import Skeleton from '@mui/material/Skeleton';



const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "max-content",
  bgcolor: "background.paper",
  // border: '2px solid #000',
  borderRadius: "2rem",
  boxShadow: 24,
  // p: 4,
};



const ProductDetailsPage = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const [productdetails, setProductdetails] = useState([]);
  const [productAccessories, setProductAccessories] = useState(null);
  const [productDetailsBanner, setProductDetailsBanner] = useState(null);
  const { slug } = useParams();

  console.log("priti", productDetailsBanner);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const formData = new FormData();
        formData.append("slug", slug);

        const response = await fetch(`${baseurl}/api/product-details`, {
          method: "POST",
          body: formData,
        });

        const data = await response.json();
        if (data.success === "1") {
          setProductdetails(data.data);
          setProductAccessories(data.accessory_category_data);
          setProductDetailsBanner(data.data.page_banner);
        } else {
          console.error("Failed to fetch Product data:", data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchProductDetails();
  }, [slug]);

  useEffect(() => {
    console.log(productdetails);
  }, [productdetails]);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  
  return (
    <>
      <Header />
      <MobileHeader />
      <div className="desktop_header_spacer"></div>
      <br />
      <SingleProduct
        imageUrl={productdetails.image}
        image2={productdetails.image2}
        image3={productdetails.image3}
        image4={productdetails.image4}
        pagetitle2={productdetails.title}
        productAccessories={productAccessories}
        description={productdetails.description}
        descriptionData={productdetails.description_data}
      />

      <UpvcVariantBannerSlider imageurl={productDetailsBanner}  />


    <section className="single_product_text_image_container"  >
      {/* single_product_text_image left start from here */}
      <div className="single_product_text_image_left single_product_text_image_left1 shadow" style={{position:"relative"}}>
         {/* <Subheading  content={productdetails.page_bottom_title} /> */}
         <div style={{height:"100%", width:'100%', padding:'2rem'}}  dangerouslySetInnerHTML={{ __html: productdetails.page_bottom_title}}></div>
      </div>
      {/* single_product_text_image left close from here */}

      {/* single_product_text_image right start from here */}
      <div className="single_product_text_image_right"  data-aos="zoom-in" >
        {
          productdetails.page_bottom_image === null ?
          <Skeleton variant="rectangular" width="100%" height={"100%"}>
          <div style={{ paddingTop: '57%' }} />
        </Skeleton>
        :
        <Img src={productdetails.page_bottom_image}  alt={"image5.png"} /> 
        }
      </div>
      {/* single_product_text_image right close from here */}
    </section>

      <Footer />




      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            className="innergetin_touch_right_container shadow"
            style={{ position: "relative" }}
          >
            <Text
              style={{ color: "#fff", fontSize: "1.3rem" }}
              text={"Tell us about your project"}
            />
            <div className="form_input_feild_container">
              <div className="pricing_container">
                <div className="input_feild_title">
                  <p style={{ marginBottom: "0", color: "#fff" }}>
                    I’m looking for
                  </p>
                </div>
                <div className="input_feild">
                  <select name="" id="">
                    <option value="">Approximate Pricing</option>
                    <option value="">Approximate Pricing</option>
                  </select>
                </div>
              </div>
              <div className="pricing_container">
                <div className="input_feild_title">
                  {" "}
                  <p style={{ marginBottom: "0", color: "#fff" }}>
                    I’m interested in
                  </p>
                </div>
                <div className="input_feild radio_input_feild">
                  <input type="radio" name="r1" />
                  <label style={{}} htmlFor="" className="radio_feild">
                    uPVC
                  </label>
                  <input type="radio" name="r1" />
                  <label htmlFor="" className="radio_feild">
                    ALU Home Interior
                  </label>
                  <input type="radio" name="r1" />
                  <label htmlFor="" className="radio_feild">
                    Commercial partition system
                  </label>
                </div>
              </div>
              <div className="pricing_container">
                <div className="input_feild_title">
                  <p style={{ marginBottom: "0", color: "#fff" }}>I need</p>
                </div>
                <div className="input_feild">
                  <select name="" id="">
                    <option value="">Single Glazing (W20 slim profile)</option>
                    <option value="">Single Glazing (W20 slim profile)</option>
                  </select>
                </div>
              </div>
              <div className="pricing_container">
                <div className="input_feild_title">
                  <p style={{ marginBottom: "0", color: "#fff" }}>
                    Delivery within
                  </p>
                </div>
                <div className="input_feild">
                  <select name="" id="">
                    <option value="">1 - 2 months</option>
                    <option value="">1 - 2 months</option>
                  </select>
                </div>
              </div>

              <button className="form_btn">
                Next
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 15 15"
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    d="m13.5 7.5l-4-4m4 4l-4 4m4-4H1"
                  />
                </svg>
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export { ProductDetailsPage };



