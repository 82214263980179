import React from "react";

export const MetaTags = ({ title, description, keyword }) => {
    return (
        <>
            <title>{title}</title>
            <meta name='title' content={title} />
            <meta name="description" content={description} />
            <meta name="keywords" content={keyword} />
        </>
    )
}