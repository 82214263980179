import React, { useEffect, useState } from "react";
import { Img } from "../../../subComponents/img";
import { Subheading } from "../../../subComponents/subHeading";
import { NavLink } from "react-router-dom";
import { baseurl } from "../../../CommonComponents/baseurl";

import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from '@mui/material/Skeleton';

function HeroSection() {
  const [category, setCategorys] = useState(null);

  const [vediourl, setVediourl] = useState("");

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const response = await fetch(`${baseurl}/api/category`, {
          method: "GET",
        });

        const data = await response.json();
        if (data.success === "1") {
          setCategorys(data.data);
        } else {
          // console.error("Failed to fetch blog data:", data.message);
        }
      } catch (error) {
        // console.error("Error fetching data:", error);
      }
    };

    fetchCategory();
  }, []);

  useEffect(() => {
    // console.log("Category state updated:", category);
  }, [category]);




  useEffect(() => {
    const fetchVedio = async () => {
      try {
        const response = await fetch(`${baseurl}/api/app-setting`, {
          method: "GET",
        });

        const data = await response.json();
        // console.log("Fetched data:", data);
        if (data.success === "1") {
          setVediourl(data.data.video);
          // console.log("Set vedio banner:", data.data);
        } else {
          // console.error("Failed to fetch vedio banner:", data.message);
        }
      } catch (error) {
        // console.error("Error fetching data:", error);
      }
    };

    fetchVedio();
  }, []);
  




  return (
    <section className="outer_hero_section">
      <div className="hero_section">
        {
          vediourl === null ?
          <Skeleton variant="rectangular" width="100%" height={"100%"}>
          <div style={{ paddingTop: '57%' }} />
        </Skeleton>
        :
        <video
        width="100%"
        height="100%"
        autoPlay
        muted
        loop
        style={{ objectFit: "cover" }}
        src={vediourl} type="video/mp4"
      >
        {/* <source src={vediourl} type="video/mp4" /> */}
      </video>
        }

      </div>
      <div className="hero_section_footer">
        {category === null ? (
                      <Skeleton variant="rectangular" width="100%" height={"100%"}>
                      <div style={{ paddingTop: '27%' }} />
                    </Skeleton>
        ) : (
          category.map((items) => {
            return (
              <>
                <NavLink
                  to={`/${items.slug}`}
                  style={{ textDecoration: "none", color: "#353839" }}
                >
                  <div className="hero_section_footer_items">
                    <Img src={items.image} alt={"interiro"} />
                    <div className="hero_section_footer_items_title">
                      <h6 style={{ fontWeight: "600" }}  dangerouslySetInnerHTML={{ __html: items.title }} ></h6>
                      <div className="arrow_div">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          style={{
                            color: "white",
                            transform: "rotate(-50deg)",
                          }}
                          width="1.3rem"
                          height="1.3rem"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            fillRule="evenodd"
                            d="M12 3.25a.75.75 0 0 1 .75.75v14.19l4.72-4.72a.75.75 0 1 1 1.06 1.06l-6 6a.75.75 0 0 1-1.06 0l-6-6a.75.75 0 1 1 1.06-1.06l4.72 4.72V4a.75.75 0 0 1 .75-.75"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </NavLink>
              </>
            );
          })
        )}
      </div>
    </section>
  );
}

export default HeroSection;
