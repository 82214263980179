import React from "react";
import Header from "../../CommonComponents/header";
import { Footer } from "../../CommonComponents/footer";
import { ContactHeroSection } from "./Components/contactHeroSection";
import { GetInTouchSection } from "./Components/getInTouchSection";
import { Heading } from "../../subComponents/Heading";
import { MobileHeader } from "../../CommonComponents/mobileHeader";
import { Helmet } from "react-helmet";
import { MetaTags } from "../../Seo/HelmetComponent";

function ContactUsPage({ title, description, keyword }) {
  return (
    <>
      <Helmet>
        <MetaTags title={title} description={description} keyword={keyword} />

        {/* OG Tags:- */}

        <meta property="og:title" content="locate us | Alu empire" />
        <meta property="og:site_name" content="Alu empire" />
        <meta property="og:url" content="https://www.aluempire.com/locate-us" />
        <meta property="og:description" content="If you have comments, queries, or questions about home interiors and upvc window and door, please call us on 90026 90068 and speak to our design experts." />
        <meta property="og:type" content="" />
        <meta property="og:image" content="https://www.aluempire.com/assets/images/Alu%20Empire%20Logo.png " />


        {/* // Twitter card:- */}

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@locate us | Alu empire" />
        <meta name="twitter:title" content="Alu Empire" />
        <meta name="twitter:description" content="If you have comments, queries, or questions about home interiors and upvc window and door, please call us on 90026 90068 and speak to our design experts." />
        <meta name="twitter:image" content="https://www.aluempire.com/assets/images/Alu%20Empire%20Logo.png" />
      </Helmet>
      <Header />
      <MobileHeader />
      <div className="mobile_header_spacer_home"></div>
      <div className="desktop_header_spacer"></div>
      <ContactHeroSection />
      <GetInTouchSection />
      <section className="contact_map_section">
        <Heading title={"Find us on google map"} />
        <br />
        <div className="contact_map_container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d56163.174471376515!2d77.22929938178875!3d28.383074855003354!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e6!4m3!3m2!1d28.3629015!2d77.2903259!4m5!1s0x390cdf679be5a621%3A0xc16d657b9ec65f6b!2salu%20empire!3m2!1d28.3933388!2d77.2494543!5e0!3m2!1sen!2sin!4v1725619265929!5m2!1sen!2sin"
            width="100%"
            height="500"
            style={{ border: "0" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>

          {/* <iframe src="" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
        </div>
      </section>
      <Footer />
    </>
  );
}
export default ContactUsPage;
